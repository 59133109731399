@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=block');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=block');
/*
@import url('//db.onlinewebfonts.com/c/868657654af992968ba24ec0c50b8482?family=AppleSDGothicNeoH');
@font-face {font-family: "AppleSDGothicNeoH"; src: url("//db.onlinewebfonts.com/t/868657654af992968ba24ec0c50b8482.eot"); src: url("//db.onlinewebfonts.com/t/868657654af992968ba24ec0c50b8482.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/868657654af992968ba24ec0c50b8482.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/868657654af992968ba24ec0c50b8482.woff") format("woff"), url("//db.onlinewebfonts.com/t/868657654af992968ba24ec0c50b8482.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/868657654af992968ba24ec0c50b8482.svg#AppleSDGothicNeoH") format("svg"); }
*/
@font-face {font-family: "AppleSDGothicNeoH";
  src: 
  url("/asset/font/868657654af992968ba24ec0c50b8482.woff2") format("woff2"), /* chrome firefox */
  url("/asset/font/868657654af992968ba24ec0c50b8482.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
}

* {
  box-sizing: border-box;

  font-family: 'Noto Sans KR', sans-serif !important;
}
body {
  margin: 0;
}

.will-animate {
  opacity: 0;
}

a {
  color:inherit;
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}

ul, ol, li, a, input, select, textarea {margin: 0;padding: 0; border: 0 none; }
ul, ol, li {list-style: none;}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.slick-active button::before {
  color: #ffc400 !important;
}
.switch-wrapper {
  position: relative;
}
.switch-wrapper > div {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 52px);
}

.mobile * {
  -webkit-tap-highlight-color: transparent;
}

hr {
  margin: 0px;
  border: 0px;
  border-top: 1px solid #dcdcdc !important;
}
hr.thick {
  margin: 0px;
  margin-left: -20px;
  margin-right: -20px;
  border: 0px;
  border-top: 9px solid #e5e5e5 !important;
}

.highlight {
  position: relative;

  z-index: 0;
}
.highlight::before {
  content:"";
  width: calc(100% + 20px);
  height: 25px;
  display: inline-block;
  background: #ffc400;
  opacity: 0.3;

  position: absolute;
  left: -10px;
  bottom: -8px;

  z-index: -1;
}
.mobile .highlight::before {
  content:"";
  width: calc(100% + 10px);
  height: 14px;
  display: inline-block;
  background: #ffc400;
  opacity: 0.3;

  position: absolute;
  left: -5px;
  bottom: -0px;
}

textarea {
  width: 100%;
  min-height: 200px;

  border-radius: 8px;
  border: solid 1px #b7b7b7;

  padding: 10px 13px;

  font-size: 16px;
  font-weight: 300;
  line-height: 1.25;
  letter-spacing: -0.4px;
}
textarea:focus {
  outline: none !important;
}
div[x-placement="bottom"] .MuiPaper-root.MuiPaper-rounded {
  border-radius: 10px !important;
  overflow: hidden;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.87) !important;
}
.MuiAccordion-root {
  box-shadow: none !important;

  background: transparent !important;

  padding: 7px 55px !important;

  transition: all 0.33s ease !important;
}
.MuiAccordion-root.Mui-expanded {
  background-color: white !important;

  border-top: 1.5px solid #484848;
  border-bottom: 1.5px solid #484848;
  border-radius: 0px !important;
}
.MuiAccordionDetails-root {
  font-size: 22px !important;
  line-height: 1.64 !important;
  letter-spacing: -0.55px !important;

  padding-bottom: 30px !important;

  transition: all 0.33s ease !important
}
.MuiAccordionSummary-root {
  font-size: 24px !important;

  color: #484848 !important;

  transition: all 0.33s ease !important
}
.MuiAccordionSummary-root.Mui-expanded {
  font-size: 26px !important;
  font-weight: bold !important;

  color: #484848 !important;
}
.MuiCollapse-root {
  font-size: 14px !important;

  color: #484848 !important;
}

.MuiList-root {
  padding: 0px !important;
  border-radius: 6px !important;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.16);
}
.MuiList-root li {
  padding: 8px 27px !important;

  font-size: 17px !important;
  font-weight: bold !important;
  line-height: 3.41;
  letter-spacing: -0.77px;
  color: #484848;
  background: white !important;

  transition: all 0.1s linear;
}
.MuiList-root li:hover {
  color: #f69f30 !important;
}
.MuiOutlinedInput-input {
  height: 40px !important;
  box-sizing: border-box !important;
}
.MuiOutlinedInput-notchedOutline {
  border-radius: 8px !important;
  border: solid 1px #b7b7b7 !important;

  transition: border 0.2s ease;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: solid 2px #f69f30 !important;
}
.react-calendar, .react-calendar--doubleView {
  border: none !important;
}

@media (max-width: 400px) {
  .MuiAccordion-root {
    padding: 7px 12px !important;
  }
  .MuiAccordionSummary-root {
    font-size: 16px !important;
    padding: 0px 0px !important;
  }
  .MuiAccordionSummary-content {
    margin: 0px 0px !important;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    font-size: 16px !important;
    min-height: 32px !important;
    margin: 0px 0px !important;
  }
  .MuiAccordionDetails-root {
    font-size: 14px !important;

    padding: 0px 0px !important;
    padding-bottom: 12px !important;
  }
  .MuiAccordionDetails-root p {
    margin: 0px 0px !important;
  }
}

.react-calendar__tile {
  display: flex;
  width: 30px;
  height: 30px;

  background: white !important;

  color: #484848 !important;

  align-items: center;
  justify-content: center;

  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.react-calendar__tile[disabled] {
  color: #999 !important;
}
.react-calendar__tile--now {
  font-weight: bold !important;
  background: initial !important;
}
.selecting .react-calendar__tile--hover {
  background-color: #ffedd5 !important;
}
.react-calendar__tile--hoverEnd {
  background: initial !important;
}
.react-calendar__tile--hoverStart {
  background: initial !important;
}

.react-calendar__tile--active {
  border-radius: 0px;
  background-color: #ffedd5 !important;
}

div:not(.react-calendar--doubleView) .react-calendar__tile--rangeEnd {
  background: none !important
}

.react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
  display: none;;
}

.react-calendar__tile--rangeEnd, .selecting .react-calendar__tile--hoverEnd {
  color: white !important;
  background: linear-gradient(to right, #ffedd5 50%, white 50%) !important;
  border-radius: 0px 20px 20px 0px !important;
}
.react-calendar__tile--rangeEnd abbr, .selecting .react-calendar__tile--hoverEnd abbr {
  z-index: 102;
}
.react-calendar__tile--rangeEnd::before, .selecting .react-calendar__tile--hoverEnd::before {
  content: '';
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 20px !important;
  background:  #f69f30 !important;
}

.react-calendar__tile--rangeStart, .selecting .react-calendar__tile--hoverStart {
  color: white !important;
  background: linear-gradient(to right, white 50%, #ffedd5 50%) !important;
  border-radius: 20px 0px 0px 20px !important;
}
.react-calendar__tile--selected {
  color: white !important;
}
.react-calendar__tile--selected abbr, .react-calendar__tile--rangeStart abbr, .selecting .react-calendar__tile--hoverStart abbr {
  z-index: 102;
}
.react-calendar__tile--selected::before {
  content: '';
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 20px !important;
  background:  #f69f30 !important;
}
.react-calendar__tile--rangeStart::before, .selecting .react-calendar__tile--hoverStart::before {
  content: '';
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 20px !important;
  background:  #f69f30 !important;
}

.react-calendar--doubleView .react-calendar__navigation__label__labelText--from {
  padding-right: 48px;
}
.react-calendar--doubleView .react-calendar__navigation__label__labelText--to {
  padding-left: 48px;
}
.react-calendar__navigation__label {
  display: flex;
  align-items: center;;
  
  font-size: 17px;
  font-weight: bold;
  color: #f69f30;
}
.react-calendar__navigation__label span {
  width: 100%;
  text-align: center;
}
.react-calendar__navigation__label__divider {
  flex: 1;
  opacity: 0;
}
.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0.25;

  background-color: white !important;
}
.react-calendar__navigation__arrow {
  background: white !important;

  font-size: 22px;
  font-weight: bold;
  color: #f69f30;
}

.slick-list {
  overflow: visible;
}

.react-modal-sheet-header {
  position: absolute !important;

  z-index: 1000;
}
.react-modal-sheet-container {
  overflow: hidden;
}